import axios from "axios";

const instance = axios.create({
  baseURL: window.config.apiUrl,
  timeout: 300000,
});

const config = (isPrivate = false, headers = {}) => {
  let config = {
    headers: {
      "Accept-language": "ka",
      ...headers,
      // "Accept-language": localStorage.getItem("lang")
      //   ? localStorage.getItem("lang").toLowerCase()
      //   : "ka",
    },
  };

  if (isPrivate && localStorage.getItem("access_token")) {
    config = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    };
  }
  return config;
};

export const getFeeds = async (data = {}, lang) =>
  await instance.get(
    "/v1/feeds",
    config(false, {
      "Accept-language": lang,
    })
  );
export const getFeed = async (id, lang = "ka") =>
  await instance.get(
    `/v1/feeds/${id}`,
    config(false, {
      "Accept-language": lang,
    })
  );
export const signIn = async (userData) =>
  await instance.post("/v1/signin", userData);

export const getMe = async () => await instance.get("/v1/me", config(true));

export const pathFeed = async ({ id, ...rest }) =>
  await instance.patch(
    `/v1/feeds/${id}`,
    rest,
    config(true, {
      "Accept-language": rest.lang,
    })
  );

export const postImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return await instance.post(`/v1/files`, formData, config(true));
};

export const postFeed = async (data) =>
  await instance.post(
    `/v1/feeds`,
    data,
    config(true, {
      "Accept-language": data.lang,
    })
  );

export const deleteFeed = async (id) =>
  await instance.delete(`/v1/feeds/${id}`, config(true));

export const getEvents = async (data = {}, lang = "ka") =>
  await instance.get(
    "/v1/events",
    config(false, {
      "Accept-language": lang,
    })
  );

export const getEvent = async (id, lang = "ka") =>
  await instance.get(
    `/v1/events/${id}`,
    config(false, {
      "Accept-language": lang,
    })
  );

export const pathEvent = async ({ id, ...rest }) =>
  await instance.patch(
    `/v1/events/${id}`,
    rest,
    config(true, {
      "Accept-language": rest.lang,
    })
  );

export const postEvent = async (data) =>
  await instance.post(
    `/v1/events`,
    data,
    config(true, {
      "Accept-language": data.lang,
    })
  );

export const deleteEvent = async (id) =>
  await instance.delete(`/v1/events/${id}`, config(true));
