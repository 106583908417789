import { useCallback, useEffect, useState } from "react";
import "../styles/sidebar.css";
import Modal from "./Modal";
import { getFeeds } from "../api/base.api";
import { useTranslation } from "react-i18next";

const Sidebar = ({ lang }) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    isLoading: true,
    topics: [],
    error: null,
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);

  const fetchTopics = useCallback(async () => {
    try {
      const { data } = await getFeeds({}, i18n.language.toLowerCase());

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        topics: data,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Invalid data",
      }));
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  return (
    <>
      <div className="mobile-header" data-lang={i18n.language === "En"}>
        <div
          className="burger-btn"
          onClick={() => setMobileSidebar(!mobileSidebar)}
        >
          {!mobileSidebar ? (
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 22H14C13.448 22 13 21.6864 13 21.3C13 20.9136 13.448 20.6 14 20.6H36C36.552 20.6 37 20.9136 37 21.3C37 21.6864 36.552 22 36 22Z"
                fill="white"
              />
              <path
                d="M36 29.4H14C13.448 29.4 13 29.0864 13 28.7C13 28.3136 13.448 28 14 28H36C36.552 28 37 28.3136 37 28.7C37 29.0864 36.552 29.4 36 29.4Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.2534 1.72649L1.69706 17.2828C1.30673 17.6732 0.768202 17.7682 0.494975 17.495C0.221748 17.2217 0.316782 16.6832 0.707107 16.2929L16.2635 0.736544C16.6538 0.34622 17.1923 0.251185 17.4655 0.524412C17.7388 0.797639 17.6437 1.33617 17.2534 1.72649Z"
                fill="white"
              />
              <path
                d="M16.2735 17.2534L0.717157 1.69706C0.326833 1.30673 0.231798 0.768202 0.505025 0.494975C0.778252 0.221748 1.31678 0.316782 1.70711 0.707107L17.2635 16.2635C17.6538 16.6538 17.7488 17.1923 17.4756 17.4655C17.2024 17.7388 16.6638 17.6437 16.2735 17.2534Z"
                fill="white"
              />
            </svg>
          )}
        </div>
        <img src="/img/logo.svg" alt="" />
      </div>
      <div
        className={mobileSidebar ? "left-sidebar active" : "left-sidebar"}
        data-lang={i18n.language === "En"}
      >
        <div className="logo-wrapper">
          <h1>
            <img src="/img/logo.svg" alt="" />
          </h1>
          <p>{t("associate")}</p>
        </div>
        <div className="sidebar-menu">
          <ul>
            {state?.isLoading ? (
              <>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
                <li className="skeleton"></li>
              </>
            ) : (
              state.topics.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    setSelectedCategory(item.id);
                    setShowModal(true);
                  }}
                >
                  <span className="menu-title">{item.title}</span>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
      <Modal
        data={state.topics.find((item) => item.id === selectedCategory)}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};
export default Sidebar;
