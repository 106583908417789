import { Navigate } from "react-router";
import { useAuth } from "../hooks/auth.hooks";

export function PrivateRoute({ children }) {
  const { user, isLoading } = useAuth();
  if (isLoading) {
    return <h1>isLoading</h1>;
  }
  return user ? <>{children}</> : <Navigate to="/signin" />;
}
