import { useCallback, useEffect, useState } from "react";
import { getMe } from "../api/base.api";

export const useAuth = () => {
  const [{ isLoading, user }, setState] = useState({
    isLoading: true,
    user: null,
  });
  const fetchMe = useCallback(async () => {
    try {
      const { data } = await getMe();
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        user: data,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      console.log("Not Authorized!!!");
    }
  }, []);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  return {
    isLoading,
    user,
  };
};
