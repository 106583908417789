import { Editor } from "@tinymce/tinymce-react";
import { useCallback, useEffect, useRef, useState, useTransition } from "react";
import { Button, Form, Modal, Table, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

import {
  deleteFeed,
  getFeed,
  getFeeds,
  pathFeed,
  postFeed,
  postImage,
} from "../api/base.api";

import { useTranslation } from "react-i18next";

const Ministers = ({
  handleCloseMinister,
  showMinisters,
  handleShowMinisters,
}) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    isLoading: true,
    topics: [],
    error: null,
  });
  const [type, setType] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    lang: "ka",
  });
  const [files, setFiles] = useState([]);
  const editorRef = useRef(null);

  const fetchTopics = useCallback(async () => {
    try {
      const { data } = await getFeeds({}, i18n.language.toLowerCase());

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        topics: data,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Invalid data",
      }));
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/png, image/jpeg",
    onDrop: async (acceptedFiles) => {
      // let file = await toBase64(acceptedFiles[0]);
      let file = acceptedFiles[0];
      console.log("file", typeof file);
      // file = file.split("base64,")[1];
      if (file)
        setCurrentItem((prev) => ({
          ...prev,
          image: file,
        }));
      if (acceptedFiles.length)
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
    },
  });

  const loadItem = useCallback(async () => {
    if (!currentItem.id) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const { data } = await getFeed(currentItem.id, currentItem.lang);
      if (data) setCurrentItem(data);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {}
  }, [currentItem?.id, currentItem?.lang]);

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  const editMinister = (curr) => {
    handleShowMinisters();
    setType(true);
    setCurrentItem((prevState) => ({
      ...prevState,
      ...curr,
    }));
  };

  const handleEditorChange = (content, editor) => {
    setCurrentItem((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevState) => ({
      ...prevState,
      title: value,
    }));
  };
  const handleChangePosition = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevState) => ({
      ...prevState,
      position: value,
    }));
  };

  const handleCHangeLang = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevState) => ({
      ...prevState,
      lang: value,
    }));
  };

  const handleOrderChange = (e) => {
    const { name, value } = e.target;
  };

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <img
        src={file.preview}
        className="img"
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        alt=""
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const handleSubmit = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      let imagePath = null;
      if (currentItem?.image && typeof currentItem.image === "object")
        imagePath = await postImage(currentItem.image);

      if (currentItem?.id) {
        const { data } = await pathFeed({
          id: currentItem.id,
          title: currentItem.title,
          position: currentItem.position,
          description: editorRef.current.getContent(),
          image:
            imagePath && imagePath.data
              ? imagePath.data.path
              : currentItem.image,
          lang: currentItem.lang ? currentItem.lang : "ka",
        });
      } else {
        const { data } = await postFeed({
          title: currentItem.title,
          position: currentItem.position,
          description: editorRef.current.getContent(),
          image: imagePath && imagePath.data ? imagePath.data.path : "",
          lang: currentItem.lang ? currentItem.lang : "ka",
        });
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      handleCloseMinister();
      setType(false);
      setFiles([]);
      setCurrentItem((prevState) => ({ lang: "ka" }));
      await fetchTopics();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Updating failed",
      }));
    }
  };

  const fetchDeleteFeed = async (id) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await deleteFeed(id);
      await fetchTopics();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Deleting failed",
      }));
    }
  };

  return (
    <>
      {state?.isLoading ? (
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            marginTop: "150px",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="tab-top">
            <button
              type="button"
              className="add"
              onClick={() => {
                handleShowMinisters();
                setType(false);
                setFiles([]);
                setCurrentItem((prevState) => ({ lang: "ka" }));
              }}
            >
              დამატება
            </button>
          </div>
          <Table hover>
            <tbody>
              {state.topics.map((item, ind) => (
                <tr key={item.id}>
                  <td>{ind + 1}</td>
                  <td>
                    <img
                      src={`${window.config.staticUrl}${item.image}`}
                      alt=""
                    />
                  </td>
                  <td>{item.title}</td>
                  <td>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.description.slice(0, 1000),
                      }}
                    ></p>
                  </td>
                  <td>
                    <div className="action-buttons">
                      <button
                        type="button"
                        className="edit"
                        onClick={() => editMinister(item)}
                        disabled={state.isLoading}
                      >
                        რედაქტირება
                      </button>
                      <button
                        type="button"
                        className="delete"
                        disabled={state.isLoading}
                        onClick={() => fetchDeleteFeed(item.id)}
                      >
                        წაშლა
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <Modal
        show={showMinisters}
        onHide={() => {
          handleCloseMinister();
          setCurrentItem("");
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{!type ? "დამატება" : "რედაქტირება"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>რიგითობა</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                value={currentItem?.position}
                onChange={handleChangePosition}
              />
              {/* <Form.Select value={currentItem.id} onChange={handleOrderChange}>
                {state?.topics.map((item, index) => (
                  <option
                    value={item.id}
                    selected={currentItem?.id === item.id}
                  >
                    {index}
                    {console.log(
                      "currentItem?.id === item.id",
                      currentItem?.id === item.id
                    )}
                  </option>
                ))}
              </Form.Select> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ენა</Form.Label>
              <Form.Select value={currentItem.lang} onChange={handleCHangeLang}>
                <option value="ka">KA</option>
                <option value="en">EN</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.image">
              <Form.Label>ქოვერ ფოტო</Form.Label>
              <div className="image-preview">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>ატვირთვა</p>
                </div>

                <aside className="thumbsContainer">
                  {files.length > 0 ? (
                    thumbs
                  ) : (
                    <div className="thumb">
                      {currentItem?.image ? (
                        <img
                          src={`${window.config.staticUrl}${currentItem?.image}`}
                          className="img"
                          alt=""
                        />
                      ) : null}
                    </div>
                  )}
                </aside>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>სამინისტრო</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                value={currentItem?.title}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>აღწერა</Form.Label>
              <Editor
                apiKey="8n7fqs31jdrigdu217i1ctrb8t8ycdmo6hzq2ghz90bu9os8"
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={currentItem?.description}
                onEditorChange={handleEditorChange}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                  ],
                  toolbar:
                    "undo redo | casechange blocks | bold italic backcolor | " +
                    "alignleft aligncenter alignright alignjustify | code" +
                    "bullist numlist checklist outdent indent | removeformat superscript subscript ",
                  content_style:
                    "body { font-family: 'firago'; font-size:14px }",
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <p>* ყველა ველის შევსება აუცილებელია</p>
          <Button onClick={() => handleSubmit()} disabled={state.isLoading}>
            {state.isLoading ? "Loading..." : "შენახვა"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Ministers;
