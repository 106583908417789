import React, { useCallback, useEffect, useState } from "react";
import HorizontalScroll from "react-scroll-horizontal";
import Column from "./Column";
import { getEvents } from "../api/base.api";
import { useTranslation } from "react-i18next";

const Content = ({ handleLang, lang }) => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    isLoading: true,
    events: [],
    error: null,
  });
  const [activeEvent, setActiveEvent] = useState(false);

  const fetchEvents = useCallback(async () => {
    try {
      const { data } = await getEvents({}, i18n.language.toLowerCase());

      const groupedByYear = data.reduce((acc, obj) => {
        const year = parseInt(obj.title);
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(obj);
        return acc;
      }, {});

      const sortedYears = Object.keys(groupedByYear).sort(
        (a, b) => parseInt(a) - parseInt(b)
      );

      const result = [];
      sortedYears.forEach((year) => {
        result.push({ year: parseInt(year), events: groupedByYear[year] });
      });

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        events: result,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Invalid data",
      }));
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <div className="content-wrapper" data-lang={i18n.language === "En"}>
      <div className="test-mode">{t("testMode")}</div>
      <div className="headline">
        <div className="top">
          <img src="/img/ge.gif" alt="Georgia flag" />
          <h1>{t("geEu")}</h1>
          <img src="/img/eu.gif" alt="Europian union flag" />
        </div>
        <h1>{t("sub")}</h1>
      </div>
      {/* <div className="lang-switcher">
        <button
          className={lang === "Ka" ? "lang active" : "lang"}
          onClick={() => handleLang("Ka")}
        >
          Ka
        </button>
        <button
          className={lang === "En" ? "lang active" : "lang"}
          onClick={() => handleLang("En")}
        >
          En
        </button>
      </div> */}
      <div className="horisontal-line"></div>

      <HorizontalScroll>
        <Column groupedByYear={state.events} setActiveEvent={setActiveEvent} />
      </HorizontalScroll>
      <div className={activeEvent ? "mobile-modal active" : "mobile-modal"}>
        <div className="header-item">
          <div className="close-modal" onClick={() => setActiveEvent(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="512"
              height="512"
            >
              <g id="_01_align_center" data-name="01 align center">
                <polygon points="24 1.414 22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12 24 1.414" />
              </g>
            </svg>
          </div>
        </div>
        <div className="body-item">
          <div
            className="img"
            style={{
              backgroundImage: `url(${window.config.staticUrl}${activeEvent?.image})`,
            }}
          ></div>
          <p>{activeEvent?.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Content;
