import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

import {
  deleteEvent,
  getEvent,
  getEvents,
  pathEvent,
  postEvent,
  postImage,
} from "../api/base.api";

const Events = () => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    isLoading: true,
    events: [],
    error: null,
  });
  const [show, setShow] = useState(false);
  const [type, setType] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    lang: "ka",
  });
  const [files, setFiles] = useState([]);

  const fetchTopics = useCallback(async () => {
    try {
      const { data } = await getEvents({}, i18n.language.toLowerCase());

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        events: data,
      }));
    } catch (error) {
      console.log(error);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Invalid data",
      }));
    }
  }, [i18n.language]);

  useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "image/png, image/jpeg",
    onDrop: async (acceptedFiles) => {
      // let file = await toBase64(acceptedFiles[0]);
      let file = acceptedFiles[0];

      // file = file.split("base64,")[1];
      if (file)
        setCurrentItem((prev) => ({
          ...prev,
          image: file,
        }));

      if (acceptedFiles.length)
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
    },
  });

  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <img
        src={file.preview}
        className="img"
        // Revoke data uri after image is loaded
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
        alt=""
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const loadItem = useCallback(async () => {
    if (!currentItem.id) return;
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const { data } = await getEvent(currentItem.id, currentItem.lang);
      console.log(data);
      if (data) setCurrentItem(data);
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    } catch (error) {}
  }, [currentItem?.id, currentItem?.lang]);

  useEffect(() => {
    loadItem();
  }, [loadItem]);

  const edit = (ind) => {
    const curr = state?.events.find((item) => item.id === ind);
    setType(true);
    setCurrentItem(curr);
    setShow(true);
  };

  const handleYearChange = (e) => {
    setCurrentItem((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleDescChange = (e) => {
    setCurrentItem((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };
  const handleCHangeLang = (e) => {
    const { name, value } = e.target;
    setCurrentItem((prevState) => ({
      ...prevState,
      lang: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      let imagePath = null;
      console.log(currentItem);
      if (currentItem?.image && typeof currentItem?.image === "object")
        imagePath = await postImage(currentItem.image);

      if (currentItem.id) {
        const { data } = await pathEvent({
          id: currentItem.id,
          title: currentItem.title,
          description: currentItem?.description,
          image:
            imagePath && imagePath.data
              ? imagePath.data.path
              : currentItem?.image,
          lang: currentItem.lang ? currentItem.lang : "ka",
        });
      } else {
        const { data } = await postEvent({
          title: currentItem.title,
          description: currentItem?.description,
          image: imagePath && imagePath.data ? imagePath.data.path : "",
          lang: currentItem.lang ? currentItem.lang : "ka",
        });
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setShow(false);
      await fetchTopics();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Updating failed",
      }));
    }
  };

  const fetchDeleteEvent = async (id) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      await deleteEvent(id);
      await fetchTopics();
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: "Deleting failed",
      }));
    }
  };
  return (
    <>
      <div className="tab-top">
        <button
          type="button"
          className="add"
          onClick={() => {
            setShow(true);
            setType(false);
            setFiles([]);
            setCurrentItem((prevState) => ({ lang: "ka" }));
          }}
        >
          დამატება
        </button>
      </div>
      <Table hover>
        <tbody>
          {state?.events?.map((item, ind) => (
            <tr key={item.id}>
              <td>{ind + 1}</td>
              <td>
                <img src={`${window.config.staticUrl}${item?.image}`} alt="" />
              </td>
              <td>{item.title}</td>
              <td>
                <p>{item?.description.slice(0, 100)}</p>
              </td>
              <td>
                <div className="action-buttons">
                  <button
                    type="button"
                    className="edit"
                    onClick={() => edit(item.id)}
                  >
                    რედაქტირება
                  </button>
                  <button
                    type="button"
                    className="delete"
                    onClick={() => fetchDeleteEvent(item.id)}
                  >
                    წაშლა
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        show={show}
        onHide={() => {
          setShow();
          setCurrentItem("");
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{!type ? "დამატება" : "რედაქტირება"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ენა</Form.Label>
              <Form.Select value={currentItem.lang} onChange={handleCHangeLang}>
                <option value="ka">KA</option>
                <option value="en">EN</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.image">
              <Form.Label>ფოტო</Form.Label>
              <div className="image-preview">
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>ატვირთვა</p>
                </div>

                <aside className="thumbsContainer">
                  {files.length > 0 ? (
                    thumbs
                  ) : (
                    <div className="thumb">
                      {currentItem?.image ? (
                        <img
                          src={`${window.config.staticUrl}${currentItem?.image}`}
                          className="img"
                          alt=""
                        />
                      ) : null}
                    </div>
                  )}
                </aside>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>წელი</Form.Label>
              <Form.Control
                type="text"
                value={currentItem?.title}
                placeholder=""
                autoFocus
                onChange={handleYearChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
              onChange={handleDescChange}
            >
              <Form.Label>აღწერა</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={currentItem?.description}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <p>* ყველა ველის შევსება აუცილებელია</p>

          <Button onClick={() => handleSubmit()}>
            {state.isLoading ? "Loading..." : "შენახვა"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Events;
