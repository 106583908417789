import { Tab, Table, Tabs } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useState } from "react";
import Ministers from "../Components/Ministers";
import Events from "../Components/Events";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const navigate = useNavigate();
  const [showMinisters, setShowMinisters] = useState(false);
  const handleCloseMinister = () => setShowMinisters(false);
  const handleShowMinisters = () => setShowMinisters(true);

  const logOut = useCallback(() => {
    localStorage.removeItem("access_token");
    navigate("/signin");
  }, [navigate]);

  return (
    <>
      <div className="dashboard-wrapper">
        <div className="header-wrapper">
          <h2>სამართავი პანელი</h2>

          <button type="button" className="primary" onClick={logOut}>
            გამოსვლა
          </button>
        </div>
        <div className="dashboard-content">
          <Tabs defaultActiveKey="saministroebi" id="uncontrolled-tab-example">
            <Tab eventKey="saministroebi" title="სამინისტროები">
              <Ministers
                showMinisters={showMinisters}
                handleCloseMinister={handleCloseMinister}
                handleShowMinisters={handleShowMinisters}
              />
            </Tab>
            <Tab eventKey="movlenebi" title="მოვლენები">
              <Events />
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
