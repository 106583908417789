import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "./styles/App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Content from "./Components/Content";
import Dashboard from "./Dashboard";
import SignIn from "./Dashboard/SignIn";
import { PrivateRoute } from "./Components/PrivateRoute";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      En: {
        translation: {
          associate: "Results achieved within the association agreement",
          geEu: "Georgia-European Union",
          sub: "chronology of important events",
          testMode: "The site works in test mode",
        },
      },
      Ka: {
        translation: {
          associate: "ასოცირების ხელშეკრულების ფარგლებში მიღწეული შედეგები",
          geEu: "საქართველო-ევროკავშირის",
          sub: "ურთიერთობების მნიშვნელოვანი მოვლენების ქრონოლოგია",
          testMode: "საიტი მუშაობს სატესტო რეჟიმში",
        },
      },
    },
    lng: "Ka", // if you're using a language detector, do not define the lng option
    fallbackLng: "Ka",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const App = () => {
  const { i18n } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [lang, setLang] = useState("Ka");
  const handleLang = (language) => {
    localStorage.setItem("lang", language);
    setLang(language);
    i18n.changeLanguage(language);
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Sidebar
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            lang={lang}
          />
          <Content
            selectedCategory={selectedCategory}
            lang={lang}
            setLang={setLang}
            handleLang={handleLang}
          />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: "/signIn",
      element: <SignIn />,
    },
  ]);

  return (
    <>
      <RouterProvider
        router={router}
        // fallbackElement={<BigSpinner />}
      />
    </>
  );
};

export default withTranslation()(App);
