import anime from "animejs";
import { useEffect, useRef, useState } from "react";

const Column = ({ groupedByYear, setActiveEvent }) => {
  const circleRefs = useRef([]);
  const [eventsVisible, setEventsVisible] = useState(false);

  useEffect(() => {
    const circles = circleRefs.current;
    const filteredCircles = circles.filter((item) => item !== null);

    filteredCircles?.forEach((circle) => {
      // Set the initial opacity to 0
      anime.set(circle, {
        opacity: 0,
        scale: 0.2,
      });

      //   // Set the initial position of the circle to a random location
      anime.set(circle, {
        translateX: anime.random(-1000, 1000),
        translateY: anime.random(-1000, 1000),
      });
    });

    // Animate the circles to their final position and opacity
    anime({
      targets: filteredCircles,
      translateX: 0,
      translateY: 0,
      opacity: 1,
      scale: 1,
      easing: "easeInOutCubic",
      duration: 1000,
      delay: anime.stagger(100),
    });
  }, [groupedByYear]);

  useEffect(() => {
    function handleResize() {
      // Call the function that animates the circles
      animateCircles();
    }

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function animateCircles() {
    if (eventsVisible) {
      return;
    }
    const circles = circleRefs.current;
    const filteredCircles = circles.filter((item) => item !== null);

    filteredCircles.forEach((circle) => {
      // Set the initial opacity to 0
      anime.set(circle, {
        opacity: 0,
        scale: 0.2,
      });

      // Set the initial position of the circle to a random location
      anime.set(circle, {
        translateX: anime.random(-1000, 1000),
        translateY: anime.random(-1000, 1000),
      });
    });

    // Animate the circles to their final position and opacity
    anime({
      targets: filteredCircles,
      translateX: 0,
      translateY: 0,
      opacity: 1,
      scale: 1,
      easing: "easeInOutCubic",
      duration: 1000,
      delay: anime.stagger(100),
    });
    setEventsVisible(true);
  }

  function hideOtherEvents() {
    // Get a list of all event elements
    const eventElements = document.querySelectorAll(".event");

    eventElements.forEach((eventElement) => {
      // Check if the current event element is visible
      if (eventElement.style.opacity !== "0") {
        // If the current event element is visible, hide it
        eventElement.style.display = "0";
      }
    });
  }

  const handleMouseEnter = (index) => {
    hideOtherEvents();
    const circle = circleRefs.current[index];
    const topEvents =
      circleRefs.current[index].parentNode.querySelector(".top-events");
    const bottomEvents =
      circleRefs.current[index].parentNode.querySelector(".bottom-events");

    // Check the size of the circle element and the viewport
    const circleRect = circle.getBoundingClientRect();
    const viewportWidth = window.innerWidth;

    // Calculate the distance from the circle to the right-hand side of the screen
    const distanceToRight = viewportWidth - circleRect.right;

    // Use the distance to customize your animation or do other actions

    if (distanceToRight < 650) {
      topEvents.classList.remove("onRight");
      bottomEvents.classList.remove("onRight");
      topEvents.classList.add("onLeft");
      bottomEvents.classList.add("onLeft");
    } else {
      topEvents.classList.remove("onLeft");
      bottomEvents.classList.remove("onLeft");
      topEvents.classList.add("onRight");
      bottomEvents.classList.add("onRight");
    }

    if (topEvents.children[0]?.style?.opacity !== "1") {
      anime({
        targets: topEvents.children,
        opacity: 1,
        translateY: [50, 0],
        duration: 500,
        easing: "easeInOutQuad",
        delay: anime.stagger(100),
      });
    }
    if (bottomEvents.children[0]?.style?.opacity !== "1") {
      anime({
        targets: bottomEvents.children,
        opacity: 1,
        translateY: [-50, 0],
        duration: 500,
        easing: "easeInOutQuad",
        delay: anime.stagger(100),
      });
    }
  };

  const handleMouseLeave = (index) => {
    const topEvents =
      circleRefs.current[index].parentNode.querySelector(".top-events");
    const bottomEvents =
      circleRefs.current[index].parentNode.querySelector(".bottom-events");
    if (topEvents?.children[0]?.style?.opacity !== "0") {
      anime({
        targets: topEvents.children,
        opacity: 0,
        translateY: 50,
        duration: 500,
        easing: "easeInOutQuad",
        delay: anime.stagger(100),
      });
    }
    if (bottomEvents?.children[0]?.style?.opacity !== "0") {
      anime({
        targets: bottomEvents.children,
        opacity: 0,
        translateY: -50,
        duration: 500,
        easing: "easeInOutQuad",
        delay: anime.stagger(100),
      });
    }
    // setEventsVisible(false);
  };

  return groupedByYear.map((item, index) => {
    const eventCount = item.events.length;
    const eventsTop = [];
    const eventsBottom = [];

    for (let i = 0; i < eventCount; i++) {
      const event = item.events[i];
      if (event) {
        if (eventCount === 1 || (eventCount === 2 && i === 0)) {
          eventsTop.push(event);
        } else if (i < Math.ceil(eventCount / 2)) {
          eventsTop.push(event);
        } else {
          eventsBottom.push(event);
        }
      }
    }

    return (
      <div
        className="column-item"
        key={index}
        onMouseLeave={() => handleMouseLeave(index)}
      >
        <div
          className={
            eventsTop.length > 3 ? "top-events multiple" : "top-events"
          }
        >
          {eventsTop.map((event, i) => (
            <div
              className="event-item"
              style={{ backgroundImage: `url(${event?.image})` }}
              key={i}
              onClick={() => setActiveEvent(event)}
            >
              {/* <span className="event-date">
                  <span>25</span> <p>მაისი</p>
                </span> */}

              <div className="event-tooltip">
                <div className="event-data">
                  <div
                    className="event-img"
                    style={{
                      backgroundImage: `url(${event.image})`,
                    }}
                  ></div>
                  <p>{event.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="circle"
          ref={(el) => (circleRefs.current[index] = el)}
          onMouseEnter={() => handleMouseEnter(index)}
        >
          <h4>{item?.year}</h4>
        </div>
        <div
          className={
            eventsBottom.length > 3 ? "bottom-events multiple" : "bottom-events"
          }
        >
          {eventsBottom.map((event, i) => (
            <div
              className="event-item"
              style={{ backgroundImage: `url(${event?.image})` }}
              key={i}
              onClick={() => setActiveEvent(event)}
            >
              {/* <span className="event-date">
                  <span>25</span> <p>მაისი</p>
                </span> */}

              <div className="event-tooltip">
                <div className="event-data">
                  <div
                    className="event-img"
                    style={{
                      backgroundImage: `url(${event.image})`,
                    }}
                  ></div>
                  <p>{event.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  });
};
export default Column;
