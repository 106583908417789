import { useEffect, useRef } from "react";

const Modal = ({ data, showModal, setShowModal }) => {
  const modalRef = useRef(null);

  const handleCloseModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseModal);
    return () => {
      document.removeEventListener("mousedown", handleCloseModal);
    };
  }, []);

  return (
    <div className={`custom-modal-wrapper ${showModal ? "active" : ""}`}>
      <div className="custom-modal-content" ref={modalRef}>
        <div
          className="cover"
          style={{
            backgroundImage: `url('${window.config.staticUrl}${data?.image}')`,
          }}
        >
          <h2>{data?.title}</h2>
          <div className="close-modal" onClick={() => setShowModal(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="512"
              height="512"
            >
              <g id="_01_align_center" data-name="01 align center">
                <polygon points="24 1.414 22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12 24 1.414" />
              </g>
            </svg>
          </div>
        </div>
        <div className="custom-modal-right">
          <div className="custom-modal-header">
            <h2>{data?.title}</h2>
            <div className="close-modal" onClick={() => setShowModal(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="512"
                height="512"
              >
                <g id="_01_align_center" data-name="01 align center">
                  <polygon points="24 1.414 22.586 0 12 10.586 1.414 0 0 1.414 10.586 12 0 22.586 1.414 24 12 13.414 22.586 24 24 22.586 13.414 12 24 1.414" />
                </g>
              </svg>
            </div>
          </div>
          <div className="description">
            <p dangerouslySetInnerHTML={{ __html: data?.description }}></p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
