import React, { useCallback, useState } from "react";
import { signIn } from "../api/base.api";
import { useNavigate } from "react-router";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = useCallback(async () => {
    if (email.trim() === "" || password.trim() === "") {
      setError("Please enter both username and password.");
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await signIn({ username: email, password });
      localStorage.setItem("access_token", data.access_token);
      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }

    // fetch("http://localhost:3001/signin", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ email, password }),
    // })
    //   .then((response) => {
    //     console.log("response", response);
    //     if (!response.ok) {
    //       throw new Error("Authentication failed");
    //     }
    //     // Handle successful login, e.g., save token or redirect
    //   })
    //   .catch((error) => {
    //     setError(error.message);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  }, [email, password]);

  return (
    <div className="signIn-wrapper">
      <div className="signIn-card">
        <h3>ავტორიზაცია</h3>

        <div className="signIn-form">
          <div className="form-item">
            <input
              placeholder="მომხმარებელი"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(false);
              }}
            />
          </div>
          <div className="form-item">
            <input
              placeholder="პაროლი"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
            />
          </div>
          {error && <p className="error">{error}</p>}
          <div className="form-item">
            <button type="button" onClick={handleSignIn} disabled={isLoading}>
              {isLoading ? "იტვირთება..." : "ავტორიზაცია"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
